export default {
    introduction: {
        title: 'First Round Financing',
        content: 'Guido Fubin i\'s financing plan aims to raise $15 million to support the development, promotion, and continuous innovation of the project. This will provide necessary resources for the development of the platform and ensure that users receive the best trading experience.'
    },
    trade: {
        balance: 'Your wallet balance is:',
        placeholder: 'Exchange Quantity',
        invalidAmount: 'Invalid Amount',
        buy: 'EXCHANGE',
        active: 'ACTIVE',
        hardCap: 'Hard Cap:',
        whitePage: 'WHITE PAGE',
    },
    menu: {
        home: 'HOME',
        order: 'ORDER',
        team: 'TEAM'
    },

    order: {
        title: 'My Order',
        investmentAmount: 'Investment Quantity',
        fibonacciAmount: 'Gift Quantity',
        referRewards: 'Refer Rewards',
        releaseAmount: 'Release Quantity',
        extractedAmount: 'Extracted Quantity',
        investmentTime: 'Investment Time',
        redemptionTime: 'Redeemable Time',
        remark: 'Note: Starting from the investment time, returning the extracted FBNQ tokens after 365 days can earn back the investment principal.',
        empty: 'Order Empty',
        extractRewards: 'Extract Rewards',
        success: 'SUCCESS',
        failed: 'FAILED',
        extracting: 'Extracting...',
    },

    team: {
        title: 'My Team',
        directlyMembers: 'Directly Members',
        directlyAmount: 'Directly Investment',
        teamMembers: 'Team Members',
        teamAmount: 'Team Investment'
    }
}
