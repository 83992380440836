<template>
  <div class="content">
    <div class="presaleAction">
      <div class="presaleRoller" id="loader">
        <div class="loader"></div>
      </div>
      <div class="white-page" @click="toWhitePage">
        <span>{{ t('trade.whitePage') }}</span>
      </div>
      <div class="balanceStrip">
        {{ t('trade.balance') }}
        <span id="balance">{{ formatNumber(balance) }}</span>
        <small id="balance-currency"> USDT</small>
      </div>
      <van-field v-model="investmentAmount" class="presaleInput" type="number" :placeholder="t('trade.placeholder')"/>
      <div class="balanceScale">
        <van-button class="balanceScaleItem" type="primary" @click="handleBalancePercentage(0.1)">10%</van-button>
        <van-button class="balanceScaleItem" type="primary" @click="handleBalancePercentage(0.25)">25%</van-button>
        <van-button class="balanceScaleItem" type="primary" @click="handleBalancePercentage(0.5)">50%</van-button>
        <van-button class="balanceScaleItem" type="primary" @click="handleBalancePercentage(0.75)">75%</van-button>
        <van-button class="balanceScaleItem" type="primary" @click="handleBalancePercentage(1)">100%</van-button>
      </div>
      <div class="presaleActionBtnWrapper">
        <van-button
            :loading="exchangeLoading"
            :loading-text="exchangeLoadingText"
            class="presaleActionBtn"
            type="primary"
            v-if="investmentAmount >= 100"
            @click="handleExchange">
          {{ t('trade.buy') }}
        </van-button>
        <van-button type="primary" class="presaleActionBtnDull" v-if="investmentAmount < 100 || !investmentAmount">
          {{ t('trade.invalidAmount') }}
        </van-button>
      </div>
    </div>
    <h4 class="presaleConversion">1
      <span id="conversion-symbol">USDT</span>
      <span class="la-arrow-right">→</span>
      <span id="conversion-amount" style="margin-right: 5px;">{{ formatNumber(saleRate) }}</span>
      <span>FBNQ</span>
    </h4>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, watchEffect} from "vue";
import {useI18n} from "vue-i18n";
import {formatNumber} from "@/utils/NumberFormatUtils";
import {useStore} from "vuex";
import {showFailToast, showSuccessToast} from "vant";
import {useRouter} from "vue-router";

const props = defineProps({
  balance: {
    type: Number,
    default: 0
  },
  quotaAmount: {
    type: Number,
    default: 0
  },
  saleRate: {
    type: Number,
    default: 0
  },
  investmentQuantity: {
    type: Number,
    default: 0
  },
  walletAddress: {
    type: String,
    default: null
  },
  investmentPercentage: {
    type: Number,
    default: 0
  }
});


const {t} = useI18n();
const investmentAmount = ref(undefined);
const handleBalancePercentage = (percentage) => {
  investmentAmount.value = Number(props.balance * Number(percentage)).toFixed(2);
}

const allowance = ref(0);

const store = useStore();
let web3 = null;
let contractInstance = null;
let tokenInstance = null;
let initFlag = false;
const initWeb3 = async () => {
  await store.dispatch('initWeb3AndContract');
  web3 = store.state.web3;
  contractInstance = store.state.contractInstance;
  tokenInstance = store.state.tokenInstance;
  initFlag = true;
}
const updateAllowance = async () => {
  try {
    const res = await tokenInstance.methods
        .allowance(props.walletAddress, contractInstance.options.address).call();
    allowance.value = web3.utils.fromWei(res, 'ether');

  } catch (error) {
    console.log('获取授权数量失败：', error)
  }
}

const exchangeLoading = ref(false)
const exchangeLoadingText = ref('交易中...')
const handleExchange = async () => {
  exchangeLoading.value = true;
  await updateAllowance();
  if (allowance.value < investmentAmount.value) {
    exchangeLoadingText.value = '授权中...'
    await approveTokens();
    exchangeLoading.value = false;
    return;
  }
  try {
    exchangeLoadingText.value = '交易中...'
    await contractInstance.methods.buy(web3.utils.toWei(investmentAmount.value, 'ether')).send({from: props.walletAddress});
    exchangeLoading.value = false;
    showSuccessToast("购买成功");
  } catch (error) {
    exchangeLoading.value = false;
    if (error.code === 4001) {
      showFailToast("取消交易");
      return;
    }
    if (error.code === -32603) {
      showFailToast("交易失败");
      return;
    }
    showFailToast("交易失败");
  }
}

const approveTokens = async () => {
  try {
    await tokenInstance.methods
        .approve(contractInstance.options.address, web3.utils.toWei(investmentAmount.value, 'ether'))
        .send({from: props.walletAddress});
    await updateAllowance();
    showSuccessToast('授权成功');
  } catch (error) {
    console.log(error)
    if (error.code === 4001) {
      showFailToast("拒绝授权");
      return;
    }
    if (error.code === -32603) {
      showFailToast("授权失败");
      return;
    }
    showFailToast("授权失败");
  }
}

const router = useRouter();
const toWhitePage = () => {
  router.push('/white-page')
}

watchEffect(async () => {
  if (props.walletAddress && initFlag) {
    await updateAllowance();
  }
})

onMounted(async () => {
  await initWeb3();
})

</script>

<style scoped>
.content >>> .van-cell:after {
  left: inherit;
  right: inherit;
  color: #fff;
}

.content >>> .van-field__control {
  color: #fff;
}
</style>

<style lang="less" scoped>

.content {
  padding: 0 20px 20px 20px;
  color: #fff;

  .presaleAction {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    margin-left: -10px;
    border-radius: 5px;
    background: rgb(18, 18, 18, 1);
    position: relative;

    .white-page {
      width: 100%;
      padding: 10px 20px;
      color: white;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 10px;
    }

    .presaleRoller {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(5px);
      border-radius: 5px;
      display: none;

      .loader {
        border: 5px solid rgb(20, 20, 20);
        border-top: 5px solid #ba7300;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 0.6s linear infinite;
      }
    }

    .balanceStrip {
      font-size: 13px;
      text-align: center;
      width: 100%;
      color: gray;
      margin-bottom: 5px;

      span {
        color: var(--text);
        font-weight: 600;
        letter-spacing: 1px;
      }
    }

    .presaleInput {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      color: white;
      background: rgb(25, 25, 25);
      font-family: 'Maven Pro', monospace;
      padding: 10px 12px;
      border-radius: 5px;
      outline: none;
      border: 1px solid #ba7300;
    }

    .balanceScale {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: 100%;

      .balanceScaleItem {
        font-size: 11px;
        padding: 5px 12px;
        background: rgb(30, 30, 30);
        border: 1px solid rgb(60, 60, 60);
        border-radius: 5px;
        width: 100%;
        text-align: center;
        cursor: pointer;
        font-family: 'Geologica', monospace;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    .presaleActionBtnWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      margin-top: 5px;

      #buy-btn {
        display: none;
      }

      .presaleActionBtn {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 12px;
        font-family: var(--head-font);
        color: white;
        background: rgba(255, 184, 31, 0.77);
        border-radius: 5px;
        font-weight: 800;
        cursor: pointer;
        box-sizing: border-box;
      }

      .presaleActionBtnDull {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 12px;
        color: gray;
        background: rgb(40, 40, 40);
        border-radius: 5px;
        font-weight: 800;
        cursor: not-allowed;
        box-sizing: border-box;
      }

      .presaleActionRoller {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 8px 12px;
        color: gray;
        background: rgb(40, 40, 40);
        border-radius: 5px;
        font-weight: 800;
        cursor: not-allowed;
        display: flex;
        align-items: center;
        justify-content: center;

        .roller-wrapper {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          padding: 2px;
          background: linear-gradient(to bottom, black, white);
          animation: roller 0.6s linear infinite;

          .roller {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgb(40, 40, 40);
          }
        }
      }
    }
  }

  .presaleConversion {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
    color: #ba7300;

    .la-arrow-right {
      height: 30px;
      align-items: center;
      margin: 0px 5px;
    }
  }

  .presaleStats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 5px;
    padding: 10px;
    background: rgb(18, 18, 18, 1);
    width: 100%;
    font-size: 13px;
    color: rgb(200, 200, 200);

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    #complete-percent {
      color: #ba7300;
      font-weight: 600;
      letter-spacing: 1px;
    }

    span {
      font-weight: 700;
      color: white;
    }
  }
}
</style>
