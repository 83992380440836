<template>
  <div class="profit">
    <div class="nav">
      <nav-bar :wallet-address="walletAddress" tab-index="2"/>
    </div>
    <div class="main-container">
      <div class="order-box">
        <span class="title">{{ t('team.title') }}</span>
        <van-divider/>
        <div class="item-box">
          <span>{{ t('team.directlyMembers') }}</span>
          <span>{{ teamInfo.directlyMembers }}</span>
        </div>
        <div class="item-box">
          <span>{{ t('team.directlyAmount') }}</span>
          <span>{{ teamInfo.directlyInvestment.toFixed(2) }} USDT</span>
        </div>
        <div class="item-box">
          <span>{{ t('team.teamMembers') }}</span>
          <span>{{ teamInfo.teamMembers }}</span>
        </div>
        <div class="item-box">
          <span>{{ t('team.teamAmount') }}</span>
          <span>{{ teamInfo.teamInvestment.toFixed(2) }} USDT</span>
        </div>
      </div>
      <span class="remark">{{ t('order.remark') }}</span>
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import WalletUtils from "@/utils/WalletUtils";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from 'vue-i18n';

const {t} = useI18n();
const store = useStore();
let web3 = null;
let contractInstance = null;
const initWeb3 = async () => {
  await store.dispatch('initWeb3AndContract');
  web3 = store.state.web3;
  contractInstance = store.state.contractInstance;
}

const teamInfo = ref({
  directlyMembers: 0,
  teamMembers: 0,
  directlyInvestment: 0,
  teamInvestment: 0
})

const getTeamInfo = async () => {
  const res = await contractInstance.methods.teamInfos(walletAddress.value).call();
  console.log(res);
  teamInfo.value.directlyMembers = Number(res.directlyMembers);
  teamInfo.value.teamMembers = Number(res.teamMembers);
  teamInfo.value.directlyInvestment = Number(web3.utils.fromWei(res.directlyInvestAmount, 'ether'));
  teamInfo.value.teamInvestment = Number(web3.utils.fromWei(res.teamInvestAmount, 'ether'));
}

const walletAddress = ref(undefined);
const getWalletAddress = async () => {
  const walletUtil = new WalletUtils();
  walletAddress.value = await walletUtil.getWalletAddress();
}

onMounted(async () => {
  await getWalletAddress();
  await initWeb3();
  await getTeamInfo();
})
</script>

<style lang="less" scoped>
.profit {
  width: 100%;
  height: 100vh;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .nav {
    flex: 0 0 auto;
  }

  .main-container {
    width: 100%;
    overflow: auto;
    flex: 1 0 0;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .order-box {
      width: 100%;
      padding: 20px;
      border: 1px solid gray;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .title {
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 800;
        display: flex;
        align-items: flex-start;
      }

      .item-box {
        width: 100%;
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-size: 14px;
      }

      .withdraw-button {
        width: 100%;
        margin-top: 10px;
      }
    }

    .remark {
      padding: 20px 0;
      color: rgba(255, 184, 31, 0.77);
      font-size: 14px;
      display: flex;
    }
  }
}
</style>
