export default {
    introduction: {
        title: '早鸟首轮',
        content: 'Guido Fubin i 的融资计划旨在筹集 1500 万美元，以支持项目的开发、推广和持续创新。这 将为平台的发展提供必要的资源，并确保用户获得最佳的交易体验。'
    },
    trade: {
        balance: '您的钱包余额：',
        placeholder: '兑换数量',
        invalidAmount: '无效数量',
        buy: '购买',
        active: '参与进度',
        hardCap: '首轮上限：',
        whitePage: '白皮书',
    },
    menu: {
        home: '首页',
        order: '订单',
        team: '团队'
    },
    order: {
        title: '我的订单',
        investmentAmount: '投资数量',
        fibonacciAmount: '赠送数量',
        referRewards: '推荐收益',
        releaseAmount: '已释放数量',
        extractedAmount: '已提取数量',
        investmentTime: '投资时间',
        redemptionTime: '可赎回时间',
        remark: '注：从投资时间起计算，365天后归还已提取的FBNQ代币，即可赚回投资本金!',
        empty: '暂无订单',
        extractRewards: '提取收益',
        success: '提取成功',
        failed: '提取失败',
        extracting: '提取中...',
    },
    team: {
        title: '我的团队',
        directlyMembers: '直推地址',
        directlyAmount: '直推业绩',
        teamMembers: '团队地址',
        teamAmount: '团队业绩'
    }
}
