<template>
  <div class="nav-bar">
    <div class="top">
      <img src="../assets/logo.png" alt=""/>
      <span>{{ walletAddress === 'Link Wallet' ? walletAddress : strUtils.sliceString(walletAddress) }}</span>
    </div>
    <div class="bottom">
      <div class="box" @click="showMenu = true">
        <van-icon style="display: block" color="#FFFFFF" name="bars" size="30px"/>
      </div>
      <div class="right-item">
        <div class="box space" @click="copyInviteUrl">
          <van-icon style="display: block" color="#FFFFFF" name="share-o" size="30px"/>
        </div>
        <div class="box">
          <van-popover
              v-model:show="showPopover"
              :actions="languageActions"
              @select="onLanguageSelect">
            <template #reference>
              <img :src="isEnglish ? imageEnglish : imageChinese" alt=""/>
            </template>
          </van-popover>
        </div>
      </div>
    </div>
    <van-popup
        v-model:show="showMenu"
        position="left"
        :style="{width:'40%',height:'100%'}">
      <div class="popup-menu">
        <img class="menu-logo" src="../assets/logo.png" alt=""/>
        <ul>
          <li @click="jumpTo('/index')"><img :src="tabIndex == 0 ? iconHomeActive : iconHome" alt=""/><span
              :class="tabIndex == 0 && 'active'">{{ t('menu.home') }}</span></li>
          <van-divider style="width: 100%"/>
          <li @click="jumpTo('/profit')"><img :src="tabIndex == 1 ? iconOrderActive : iconOrder" alt=""/><span
              :class="tabIndex == 1 && 'active'">{{ t('menu.order') }}</span></li>
          <van-divider style="width: 100%"/>
          <li @click="jumpTo('/team')"><img :src="tabIndex == 2 ? iconTeamActive : iconTeam" alt=""/><span
              :class="tabIndex == 2 && 'active'">{{ t('menu.team') }}</span></li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>
<script setup>
import {defineProps, ref} from 'vue'
import StringUtils from "@/utils/StringUtils";
import {showSuccessToast} from "vant";
import imageEnglish from "../assets/en.png";
import imageChinese from "../assets/cn.png";
import {useI18n} from 'vue-i18n';
import iconHome from "@/assets/index/home-n.png";
import iconHomeActive from "@/assets/index/home-y.png";
import iconOrder from "@/assets/index/profit-n.png";
import iconOrderActive from "@/assets/index/profit-y.png";
import iconTeam from "@/assets/index/team_n.png";
import iconTeamActive from "@/assets/index/team_y.png";
import {useRouter} from "vue-router";

const router = useRouter();

const jumpTo = (path) => {
  router.push(path)
}

const {locale, t} = useI18n({useScope: 'global'});

const props = defineProps({
  walletAddress: {
    type: String,
    default: 'Link Wallet'
  },
  isHome: {
    type: Boolean,
    default: true
  },
  tabIndex: {
    type: Number,
    default: 0
  }
});

const showPopover = ref(false);

const languageActions = ref([
  {text: "EN"},
  {text: "CN"}
]);
const isEnglish = ref(true)
const onLanguageSelect = ((action) => {
  isEnglish.value = action.text === 'EN';
  if (action.text === 'EN') {
    locale.value = 'en';
  } else {
    locale.value = 'cn';
  }
});

const showMenu = ref(false);

const strUtils = new StringUtils();

const INVITE_PREFIX = ref('https://sale.fibonacci.cyou/#/index?inviteAddress=');

const copyInviteUrl = (() => {
  if (!props.walletAddress) return;
  let copy_ele = document.getElementById("copy");
  let body_ele = document.getElementsByTagName("body")[0];
  if (!copy_ele) {
    copy_ele = document.createElement("input");
    copy_ele.id = "copy";
    copy_ele.type = "text";
    copy_ele.value = INVITE_PREFIX.value + props.walletAddress.trim();
    copy_ele.readOnly = true;
    copy_ele.style.position = "fixed";
    copy_ele.style.zIndex = -1000;
    copy_ele.style.left = `-1000px`;
    body_ele.append(copy_ele);
  }
  copy_ele.select();
  document.execCommand("copy");
  body_ele.removeChild(copy_ele);
  showSuccessToast("链接复制成功");
});
</script>
<style scoped lang="less">
/deep/ .van-popover__wrapper {
  display: block;
}

/deep/ .van-popover__action {
  width: auto;
}

/deep/ .van-popup {
  background-color: #282828;
}

.nav-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #141416;

  .popup-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    box-sizing: border-box;

    .menu-logo {
      width: 100%;
    }

    ul {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      li {
        font-size: 14px;
        color: gray;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          height: 24px;
          width: 24px;
          padding: 3px;
          box-sizing: border-box;
        }

        .active {
          color: rgba(255, 184, 31, 0.77);
        }
      }
    }
  }

  .top {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      height: 30px;
    }

    span {
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 4px;
      height: 30px;
      border: 1px solid rgba(255, 184, 31, 0.77);
      color: rgba(255, 184, 31, 0.77);
      font-weight: bold;
      align-items: center;
      box-sizing: border-box;
    }
  }

  .bottom {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;

    .box {
      padding: 4px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: #3b3b3b;

      img {
        height: 24px;
        width: 24px;
        padding: 3px;
        border-radius: 12px;
        display: block;
      }
    }

    .right-item {
      display: flex;
      flex-direction: row;

      .box {
        padding: 4px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #3b3b3b;

        img {
          height: 24px;
          width: 24px;
          padding: 3px;
          border-radius: 12px;
          display: block;
        }
      }

      .space {
        margin-right: 10px;
      }
    }

  }
}
</style>
